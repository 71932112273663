import React, { useState } from "react"
import { GatsbyImage } from 'gatsby-plugin-image'
import SectionTitle from "./sectionTitle";
import { useStaticQuery, graphql } from "gatsby";

const Works = ({filterWorks}) => {

    const works = useStaticQuery(graphql`
        query Works {
            allWorksJson {
                edges {
                  node {
                    title
                    textColor
                    tech
                    status
                    logo {
                      src {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                      link
                      orientation
                    }
                    jsonId
                    images {
                      alt
                      view
                      text
                      src {
                        childImageSharp {
                          gatsbyImageData
                        }
                      }
                    }
                    id
                    displayOnHome
                    description
                    buttons {
                      link
                      newTab
                      text
                    }
                    bgColor
                    shadowColor
                  }
                }
              }
          }     
    `) 

    // console.log(works.allWorksJson.edges.map((data)=>{return(data.node.images.map((image)=>{return(image.image)}))}))
    // console.log(works.allWorksJson.edges.map((data)=>{return(data.node.bgColor)}))
    return (
        <>
            <section className="border-1 border-t border-brand-black border-opacity-10 py-14 md:py-28">
                <div className="container mx-auto">
                    <SectionTitle 
                        wrapperClassName=""
                        alignCenter={false}
                        title="Our Work."
                        minititle=""
                        type="h2"
                        invert={false}
                        childrenClassName=""
                        buttonText="View complete portfolio"
                        buttonType="btn-filled"
                        buttonLink="/portfolio/"
                        >
                        <div className="flex flex-col lg:flex-row space-y-8 lg:space-y-0 lg:space-x-16">
                            <p className="">Every venture is special to us. They are not just another project to add to our portfolio, but a chance for us to deliver our mission of serving the world for the better through our work and services.</p>
                            <p className="">Designed and developed in Kuala Lumpur (KL), Malaysia, our work has reached millions of people around the world. And here are some of the projects we are proud to be a part of.</p>
                        </div>
                    </SectionTitle>
                </div>
            </section>
            {works.allWorksJson.edges.map(
                (data) => {
                    if (filterWorks){
                        return(
                            <>
                            {data.node.status === "live" && 
                                <section className={`border-1 border-t border-brand-black border-opacity-10 py-0 ${data.node.displayOnHome ? 'block' : 'hidden'}`}>
                                    <Work data={data.node}/>
                                </section>
                            }
                            </>
                        )
                    } else {
                       return(
                            <>
                            {data.node.status === "live" && 
                                <section className="border-1 border-t border-brand-black border-opacity-10 py-0">
                                    <Work data={data.node}/>
                                </section>
                            }
                            </>
                        )
                    }
                }
            )}
        </>
    );
};


// Individual Works
const Work = ({data}) => {
    const [tabActive, setTabActive] = useState(data.images[0])

    return(
        <div className={`sm:container mx-auto`}>
            <div className="flex flex-row md:space-x-5 flex-wrap md:flex-nowrap items-top">
                <div className="w-full md:w-5/12">
                    <div className="px-4 sm:px-0 py-4">
                        <div className="my-8">
                            {data.logo.map((item)=>{
                                return(
                                    <a href={item.link} title={data.title} target="_blank" rel="noopener noreferrer" className="inline-block">
                                        <GatsbyImage 
                                        image={item.src.childImageSharp.gatsbyImageData} 
                                        width="290" height="100" 
                                        alt={`${data.title} logo`} objectPosition="left" objectFit="contain"  
                                        imgClassName="inline w-auto"
                                        // style={`${data.jsonId == "odn-ma" && width:'auto !important' }`}
                                        // style={{width;""}}
                                        // data.jsonId === "odn-ma" && { width:'auto !important' }
                                        className={`${item.orientation === "square" && "max-h-14 w-auto"} ${item.orientation === "horizontal" && "h-8 w-auto"}`}
                                        />
                                    </a>
                                )
                            })}
                        </div>
                        <h3 className="text-3xl mb-3">{data.title}</h3>
                        <p className="mb-6 leading-normal">
                            {data.description}
                        </p>
                        <p className="text-gray-500 text-sm leading-loose flex flex-col">
                            {data.tech.map((item, index)=>{return(
                                <span key={index} className="inline-block">
                                    {item}
                                </span>
                            )})}
                        </p>
                        <div className="mt-10 mb-8 inline-flex flex-col">
                            {data.buttons.map((button, index)=>{
                                return(
                                    <a key={index} href={button.link} target={button.newTab ? "_blank" : ""} className={`${data.bgColor === null ? "bg-gray-900" : `${data.bgColor}`} text-center bg-opacity-100 hover:bg-opacity-90 shadow-2xl ${data.shadowColor} text-white px-4 py-3 mb-2 text-sm rounded transition-ease-in`} rel="noopener noreferrer">{button.text}</a>
                                )
                            })}
                        </div>
                    </div>
                </div>
                <div className={`${data.bgColor} bg-opacity-5 w-full md:w-7/12`}>
                    <div className={`h-full flex items-center`}>
                        <div className="w-full pt-12 px-6 pb-6">
                            <div className="relative h-72 md:h-96 w-full flex items-center justify-center mb-10">
                                {data.images.map((item, index)=>{
                                    // console.log(item.image)
                                    return(
                                    <div key={index} className={`absolute top-0 left-0 right-0 h-full m-auto shadow-2xl ${data.shadowColor} rounded overflow-hidden mb-12 transition-ease-in
                                                    ${tabActive === item ? 'opacity-100 scale-100':'opacity-0 scale-90'}
                                                    ${(item.view.toLowerCase()) === "mobile" ? 'w-40 md:w-52' : ''}
                                                    ${(item.view.toLowerCase()) === "desktop" ? 'w-72 md:w-96' : ''}
                                    `}>
                                        <GatsbyImage
                                            image={item.src.childImageSharp.gatsbyImageData}
                                            objectFit={'cover'}
                                            className="static"
                                            alt={item.alt ? item.alt : `Simple and clean website design for ${data.title} on ${item.view} view.`}
                                            draggable={false}
                                            />
                                    </div>
                                    )
                                })}
                            </div>
                            <div className="flex flex-row flex-wrap items-center justify-center">
                                {data.images.map((item, index)=>{
                                    return(
                                        <button
                                            key={index}
                                            onClick={() => {
                                                setTabActive(item)
                                                // console.log(item)
                                            }}
                                            className={`${data.textColor} bg-white rounded text-xl py-3 px-4 transition-all duration-240 ease-in-out ${tabActive === item? `shadow-lg ${data.shadowColor} transform scale-110` : ''}`}
                                            type="button">
                                                {(item.view.toLowerCase()) === "mobile" ? <i className="icon-smartphone"></i> : null}
                                                {(item.view.toLowerCase()) === "desktop" ? <i className="icon-desktop"></i> : null}
                                                <p className="text-xs">
                                                    {item.text}
                                                </p>
                                        </button>
                                    ) 
                                })}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Works;
  