import React from "react"
import { Swiper, SwiperSlide } from 'swiper/react';
import SwiperCore, { EffectFade, Autoplay, Navigation } from 'swiper';

import 'swiper/css';
import 'swiper/css/effect-fade';
import "swiper/css/pagination"
import "swiper/css/navigation"

SwiperCore.use([EffectFade, Autoplay, Navigation]);

const Testimonial = () => {

    const quotes = [
        {
            quote: [
                'Definitely one of the best firms out there and',
                'one we want to work with for the longest time.'
            ],
            person: 'Jahabar Sadiq, CEO, The Malaysian Insight'
        },
        {
            quote: [
                'A breath of fresh air, the system Appleseeds provided is ',
                '100x times better than the Wordpress site we used to have.',
            ],
            person: 'Francesca, Marketing Manager, HolidayInn Melaka'
        },
        {
            quote: [
                'My friends from over 40 countries who are in the tech ',
                'industry were amazed at their work!',
            ],
            person: 'Khailee Ng, Founder of SAYS.com and Managing Partner of 500 Startups'
        },
        {
            quote: [
                'Appleseeds team have always been patient,',
                'understanding, honest and kind.',
            ],
            person: 'Aaron See, Senior Marketing Manager, BMCC Malaysia'
        },
    ]

    return (
        <section className="border-1 border-t border-brand-black border-opacity-10">
            <div className="container mx-auto">
                <div className="flex mx-auto w-full md:w-5/6 py-24">
                    <Swiper
                        modules={[EffectFade]}
                        slidesPerView={1}
                        effect="fade"
                        centeredSlides={true}
                        loop={true}
                        autoplay={{
                            "delay": 3500,
                            "disableOnInteraction": false
                        }}
                    >
                        {quotes.map((quote, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <blockquote className="flex flex-col items-center justify-center h-full bg-white text-center">
                                        <p className="text-2xl md:text-4xl font-pt-serif leading-tight mb-4">
                                            {quote.quote.map((line, index) => {
                                                return (
                                                    <>
                                                        <span key={index}>
                                                            {line}
                                                        </span>
                                                        <br className="hidden xl:block" />
                                                    </>
                                                )
                                            })}
                                        </p>
                                        <em className="text-brand-gold text-base md:text-1.5xl">— {quote.person}</em>
                                    </blockquote>
                                </SwiperSlide>
                            )
                        })}
                    </Swiper>
                </div>
            </div>
        </section>
    );
};

export default Testimonial;
