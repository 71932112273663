import React from "react"
import { StaticImage } from 'gatsby-plugin-image'
import SectionTitle from "./sectionTitle";

const Client = () => {
    return (
        <section className="bg-gray-900 py-24">
            <div className="container mx-auto">
                <div>
                  <SectionTitle
                    wrapperClassName="mb-10"
                    alignCenter={false}
                    title="Brands we&rsquo;ve worked with"
                    minititle=""
                    type="h2"
                    invert={true}
                    childrenClassName=""
                    buttonText=""
                    buttonType=""
                    buttonLink=""
                    >
                    <p>
                        Here are some other brands we&rsquo;ve worked and grown with.
                    </p>
                </SectionTitle>
                  <ul className="flex flex-row flex-wrap items-center">
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="http://www.cupwater.com" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/jan-logo.png" draggable={false} width={138} height={30} objectFit={'contain'} alt="Jantzen water company in Malaysia"/></a></li>
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="http://www.kancilawards.com" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/kan-logo.png" draggable={false} width={75} height={50} objectFit={'contain'} alt="Kancil Awards by 4As for designers"/></a></li>
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="http://www.yeastbistronomy.com" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/yst-logo.png" draggable={false} width={50} height={50} objectFit={'contain'} alt="Yeast Bistronomy is a French restaurant in Bangsar, Kuala Lumpur"/></a></li>
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="http://www.themalaysianinsider.com" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/tmi-old-logo.png" draggable={false} width={156} height={50} objectFit={'contain'} alt="The Malaysian Insider was one of the top 10 websites in Malaysia"/></a></li>
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="http://www.d-awards.com.my" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/mda-logo.png" draggable={false} width={183} height={50} objectFit={'contain'} alt="Malaysia Digital Association"/></a></li>
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="http://www.samasamahotels.com" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/smh-logo.png" draggable={false} width={103} height={50} objectFit={'contain'} alt="Sama Sama Hotels website for Kuala Lumpur International Airport 1 and 2"/></a></li>
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="http://www.imfirstclass.com" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/fcl-logo.png" draggable={false} width={137} height={30} objectFit={'contain'} alt="First Class magazine is a luxury magazine produced by ichimedia company in Malaysia focusing on luxury travel"/></a></li>
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="http://www.goingplacesmagazine.com" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/gop-logo-white.png" draggable={false} width={159} height={40} objectFit={'contain'} alt="Malaysia Airlines in Flight magazine website design"/></a></li>
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="https://www.bfmedge.com" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/bfe-logo-white.png" draggable={false} width={192} height={25} objectFit={'contain'} alt="BFM Edge is a platform that helps entrepreneur to future skill their talent in areas of leadership and innovation"/></a></li>
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="https://grooves.com.my/" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/grv-logo-white.png" draggable={false} width={138} height={35} objectFit={'contain'} alt="Grooves is the Japan&rsquo;s largest domestic recruiting platform"/></a></li>
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="https://isabel.com.my/" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/isb-logo-white.png" draggable={false} width={134} height={30} objectFit={'contain'} alt="Isabel Restaurant & Bar is the city&rsquo;s most essential destination to savour regional cuisine in a sophisticated setting"/></a></li>
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="http://www.bmcc.org.my" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/bmc-logo-white.png" draggable={false} width={177} height={30} objectFit={'contain'} alt="British Malaysian Chamber of Commerce"/></a></li>
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="https://www.mondayshow.com.my/" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/mss-logo.png" draggable={false} width={70} height={70} objectFit={'contain'} alt="Monday Show Entertainment established its musical theatre school in 2012"/></a></li>
                      <li className="transition-ease-out opacity-50 hover:opacity-100 mb-8 mr-8"><a href="https://500.co/" target="_blank" rel="noreferrer"><StaticImage src="../images/portfolio/logos/soo-logo-white.png" draggable={false} width={118} height={40} objectFit={'contain'} alt="500 Startups is a global venture capital firm based in Silicon Valley"/></a></li>
                  </ul>
                </div>
            </div>
        </section>
    );
};
  
export default Client;
  